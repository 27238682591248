
import Home from '@/views/Home.vue'
// import users from './modules/user'
// import login from './modules/login'
 import content from './modules/content'

const Index = () =>
  import(/* webpackChunkName: 'index' */ '@/views/channels/Index.vue')
  const TemplateOne = () =>
  import(/* webpackChunkName: 'templateone' */ '@/views/channels/Template_one.vue')
const NoFound = () =>
  import(/* webpackChunkName: 'notfound' */ '@/views/NotFound.vue')
const About = () =>
  import(/* webpackChunkName: 'about' */ '@/views/About.vue')
const Author = () =>
  import(/* webpackChunkName: 'author' */ '@/views/Author.vue')
const SpeColumn = () => 
  import(/* webpackChunkName: 'specolumn' */ '@/views/SpeColumn.vue')
const Rookie = () => 
  import(/* webpackChunkName: 'rookie' */ '@/views/Rookie.vue')
const Budao = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/Budao.vue')
const EventLog = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/EventLog.vue')
const Fastpage = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/Fastpage.vue')
const Explorer = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/Explorer.vue')
const BdtyPage = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/BdtyPage.vue')
const Summit = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/Summit.vue')
const Apply = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/Apply.vue')
const User = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/User.vue')
const Search = () => 
  import(/* webpackChunkName: 'notfound' */ '@/views/Search.vue')

export default {
  linkExactActiveClass: 'budao-this',
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '',
          name: 'index',
          component: Index
        },
        {
          path: 'contact',
          name: 'about',
          component: About
        },
        {
          path: 'author',
          name: 'author',
          component: Author
        },
        {
          path: 'article',
          name: 'fastpage',
          component: Fastpage
        },
        {
          path: '/index/:catalog',
          name: 'catalog',
          component: TemplateOne
        },
        {
          path: 'specolumn',
          name: 'specolumn',
          component: SpeColumn
        },
        {
          path: 'search',
          name: 'sea',
          component: Search
        },
        {
          path: 'Community',
          name: 'rookie',
          component: Rookie
        },
        {
          path: 'budao',
          name: 'budao',
          component: Budao
        },
        {
          path: 'eventlog',
          name: 'eventlog',
          component: EventLog
        },
        {
          path: 'explorer',
          name: 'explorer',
          component: Explorer
        },
        {
          path: 'bdtypage',
          name: 'bdtypage',
          component: BdtyPage
        },
        {
          path: 'Activity',
          name: 'summit',
          component: Summit
        },
        {
          path: 'apply',
          name: 'apply',
          component: Apply
        },
        {
          path: 'user',
          name: 'user',
          component: User
        },
        ...content
      ]
    },    
    {
      path: '/404',
      name: '404',
      component: NoFound
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
}
