<template>
  <div id="app">   
    <budao-header></budao-header>
    <router-view></router-view>
    <budao-footer></budao-footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'


export default {
  name: 'App',
  created() {
    console.log(this.$route.path)
  },
  
  components: {
    'budao-header': Header,
    'budao-footer': Footer
  }
}
</script>

<style lang="scss">
@import 'assets/vendor/font-awesome/css/all.min.css';
@import 'assets/vendor/bootstrap-icons/bootstrap-icons.css';
@import 'assets/vendor/swiper/swiper-bundle.min.css';
@import 'assets/css/style.css';

</style>
