import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import { i18n } from './i18n/index'
import store from './store'
import Pop from './components/modules/pop'
import VueClipboard  from 'vue-clipboard2'

Vue.config.productionTip = false
Vue.use(Pop)
Vue.use(VueClipboard)
new Vue({
  router,
  i18n, 
  store,
  render: h => h(App),
}).$mount('#app')
