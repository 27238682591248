
export const h = {
    system: "Vue后台管理系统",
    full: "全屏显示",
    account: "我的账户",
    invoice: "原始单据",
    reconciliation: "财务对账",
    record: "对账记录",
    report: "月结报表",
    setting: "系统设置",
    login: "登录",
    tips: "用户名和密码随便填",
    administrator: "管理员",
    placeUser: "请输入用户名",
    palcePass: "请输入密码",
    palceCode: "请输入验证码",
    accounts: "账号",
    password: "密码",
    code: "验证码",
    day_hot:"日搜热榜",
    month_hot:"月搜热榜",
    views:"浏览量:"
  }