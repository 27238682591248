<template>
  <header class="header-sticky header-absolute">
    <!-- Logo Nav START -->
    <nav class="navbar navbar-expand-xl">
      <div class="container">
        <!-- Logo START -->
        <div class="d-sm-none">
          <svg
            @click="jumpUrl('sea')"
            class="svg-inline--fa fa-magnifying-glass"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="magnifying-glass"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
            ></path>
          </svg>
        </div>
        <a class="navbar-brand me-0" href="/">
          <img
            class="light-mode-item navbar-brand-item"
            src="../assets/img/logo.png"
            alt="logo"
          />
          <img
            class="dark-mode-item navbar-brand-item"
            src="../assets/img/logob.png"
            alt="logo"
          />
        </a>
        <!-- Logo END -->

        <!-- Main navbar START -->
        <div class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav navbar-nav-scroll dropdown-hover mx-auto">
            <!-- Nav item -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle active"
                href="/"
                >Home</a
              >
            </li>

            <!-- Nav item -->
            <li
              v-for="(item, index) in lists"
              :key="'listitem' + index"
              class="nav-item dropdown"
            >
              <a
                class="nav-link dropdown-toggle"
                :href="item.etitle=='Community'?  'http://lt.budaonews.com/'  : '/#/' + item.etitle"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false"
                >{{ item.title }}</a
              >
              <ul class="dropdown-menu">
                <!-- Dropdown submenu -->

                <!-- <li><a class="dropdown-item" href="#/fastpage">快速页面</a></li>
                <li><a class="dropdown-item" href="#/specolumn">专栏</a></li> -->
              </ul>
            </li>
          </ul>
        </div>
        <!-- Main navbar END -->
        <form
          class="bg-body border border-primary rounded-5 position-relative d-none d-sm-block"
          style="margin-right: 0px" @click="jumpUrl('sea')"
        >
          <div class="input-group" style="margin-right: 0px">
            <input
              style="margin-right: 0px; padding: 5px"
              class="form-control focus-shadow-none border-0"
              type="email"
              placeholder=""
            />
            <svg
              @click="jumpUrl('sea')"
              class="svg-inline--fa fa-magnifying-glass search-box-icon"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="magnifying-glass"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
              ></path>
            </svg>
          </div>
        </form>
        <!-- Buttons -->
        <ul class="nav align-items-center dropdown-hover ms-sm-2">
          <!-- Dark mode option START -->
          <li
            class="nav-item dropdown dropdown-animation me-2 d-none d-sm-block"
          >
            <button
              class="btn btn-link mb-0 p-0"
              id="bd-theme"
              type="button"
              aria-expanded="false"
              data-bs-toggle="dropdown"
              data-bs-display="static"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="22"
                class="bi bi-circle-half theme-icon-active fill-mode fa-fw"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"
                />
                <use href="#"></use>
              </svg>
            </button>

            <ul class="dropdown-menu min-w-auto" aria-labelledby="bd-theme">
              <li class="mb-1">
                <button
                  type="button"
                  class="dropdown-item d-flex align-items-center"
                  data-bs-theme-value="light"
                >
                  <svg
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-brightness-high-fill fa-fw mode-switch me-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"
                    />
                    <use href="#"></use></svg>Light
                </button>
              </li>
              <li class="mb-1">
                <button
                  type="button"
                  class="dropdown-item d-flex align-items-center"
                  data-bs-theme-value="dark"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-moon-stars-fill fa-fw mode-switch me-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"
                    />
                    <path
                      d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"
                    />
                    <use href="#"></use></svg>Dark
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="dropdown-item d-flex align-items-center active"
                  data-bs-theme-value="auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-circle-half fa-fw mode-switch me-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"
                    />
                    <use href="#"></use></svg>Auto
                </button>
              </li>
            </ul>
          </li>
          <!-- Dark mode option END -->
          <li
            class="nav-item dropdown dropdown-animation d-none me-2 d-sm-block"
          >
            <button
              class="btn btn-link mb-0 p-0"
              id="bd-theme"
              type="button"
              aria-expanded="false"
              data-bs-toggle="dropdown"
              data-bs-display="static"
            >
              <a href="" class="mb-0"
                ><img
                  class="light-mode-item"
                  src="../assets/img/fy.png"
                  width="20px"
                  height="22px"
                />
                <img
                  class="dark-mode-item"
                  src="../assets/img/translate.png"
                  width="20px"
                  height="22px"
                />
              </a>
            </button>
            <ul
              class="dropdown-menu min-w-auto"
              aria-labelledby="languageSwitcher"
            >
              <li>
                <a class="dropdown-item me-4" @click="changeLangEvent()">
                  <svg
                    t="1723340232912"
                    class="icon fa-fw me-1"
                    viewBox="0 0 1536 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="4463"
                  >
                    <path
                      d="M0.341333 1.536h1534.464v1022.293333H0.341333z"
                      fill="#DE2910"
                      p-id="4464"
                    ></path>
                    <path
                      d="M286.805333 175.36l30.378667 93.269333h98.56l-79.616 57.770667 30.293333 93.44-79.616-57.685333-79.616 57.685333 30.378667-93.44-79.616-57.770667h98.56l30.293333-93.269333z m256.682667-48.725333l-1.877333 32.256 30.037333 11.861333-31.573333 8.106667-2.048 32.256-17.322667-27.562667-31.317333 8.106667 20.736-24.832-17.322667-27.306667 30.037333 11.776 20.650667-24.746667z m110.677333 104.533333l-14.848 28.757333 23.04 23.04-32-5.290666-14.933333 28.757333-4.949333-32.085333-32-5.12 29.013333-14.677334-5.12-31.914666 22.869333 22.954666 28.928-14.421333z m-33.109333 130.986667l9.813333 30.805333h32.426667l-26.197333 18.944 9.813333 30.72-26.197333-19.114667-26.112 18.944 9.898666-30.549333-26.112-18.944h32.426667l10.24-30.805333z m-77.653333 98.474666l-2.133334 32.256 30.037334 11.946667-31.317334 8.021333-2.048 32.256-17.408-27.306666-31.402666 8.021333 20.650666-24.832-17.322666-27.306667 30.208 11.776 20.650666-24.746666z"
                      fill="#FFDE00"
                      p-id="4465"
                    ></path>
                  </svg>
                  Chinese</a
                >
              </li>
              <li>
                <a class="dropdown-item me-4" @click="changeLangEvent()"
                  ><img
                    class="fa-fw me-2"
                    src="../assets/images/flags/uk.svg"
                    alt=""
                  />English
                </a>
              </li>
            </ul>
          </li>
          <!-- Sign up button -->

          <!-- Buy now button -->
          <li class="nav-item d-none d-sm-block me-2">
            <a href="" class="mb-0"
              ><img
                class="dark-mode-item"
                src="../assets/img/phone.png"
                width="14px"
                height="10px"
              />
              <img
                class="light-mode-item"
                src="../assets/img/phone2.png"
                width="14px"
                height="10px"
              />
            </a>
          </li>

          <li class="nav-item me-2" @click.prevent="changeIsShow()">
            <a href="" class="mb-0"
              ><img
                class="light-mode-item"
                src="../assets/img/my.png"
                width="20"
                height="22"
              />
              <img
                class="dark-mode-item"
                src="../assets/img/myavatar.png"
                width="20"
                height="22"
              />
            </a>
          </li>
          <!-- Responsive navbar toggler -->
          <li class="nav-item d-none d-sm-block">
            <button
              class="navbar-toggler ms-sm-3 p-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-animation">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Logo Nav END -->
    <div class="center-fixed logon" v-if="isShow">
      <div  @click="isShow=false" class="btn-close1 position-absolute" style="top:20px;right:20px" >X</div>
      <div class="row w-100">
        <div class="col-5 d-none d-sm-block">
          <img style="border-top-right-radius: 0px; 
  border-bottom-right-radius: 0px; " src="../assets/img/logobg.png" alt="" />
        </div>
        <div class="col-12 col-sm-7 p-3">
          <div class="d-flex align-items-center" style="margin-top:25px;">
            <div class="me-3 " style="font-size:22px;color:#555555;font-weight:800">免密码登录</div>
            <div style="font-size:16px;color:#999999;font-weight:400">密码登录</div>
          </div>
          <div class="d-flex w-100 " style="margin-top:38px;">
          <div class="login-phone w-100"><input type="text" class="form-control" id="" placeholder="请输手机号" ></div></div>
          <div class="d-flex login-validate align-items-center w-100" style="margin-top:20px;">
            <div class="input-full align-self-stretch"><input type="text" class="form-control"></div>
            <div class="px-1 px-sm-2"><a  style="color:#4547DB;">获取验证码</a></div></div>
          <div style="margin-top:25px;"><a>切换邮箱登录</a></div>
          <div style="margin-top:40px;" class="w-100"><button class="btn btn-primary" style="width:100%">注册/登录</button></div>
          <div class="sanfang text-center" style="margin-top:34px;"><span><img  class="me-1" style="" src="../assets/img/wechat.png" alt="">微信</span><span><img class="ms-2 me-1" src="../assets/img/MetaMask.png" alt="">MetaMask</span></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
//import { mapState } from 'vuex'
//import TransMix from "@/mixin/translate";
import { getNavCat } from "@/api/cat";
export default {
  name: "budao_header",
  data() {
    return {
      isHover: false,
      hoverCtrl: {},
      hasMsg: false,
      lists: [],
      isShow: false,
      language: localStorage.getItem("lang") || this.$store.state.lan,
    };
  },

  mounted() {
    getNavCat().then((res) => {
      if (res.state === 200) {
        this.lists = res.data;
        console.log(res.data);
      }
    });
  },

  methods: {
    changeIsShow() {
      console.log('ok login')
      this.isShow = !this.isShow;
    },
    jumpUrl(url) {
      this.$router.push({
        name:url
      })
    },
    changeLangEvent() {
      
      console.log(this)
      
      if (this.$store.state.lan == "zh") {
        localStorage.setItem("locale", "zh");
        this.$i18n.locale = localStorage.getItem("locale");
        localStorage.setItem("lang", "zh");
        this.$store.commit('setLan','zh')
        location.reload(); //重新加载
      } else if (this.$store.state.lan == "en") {
        localStorage.setItem("locale", "en");
        this.$i18n.locale = localStorage.getItem("locale");
        localStorage.setItem("lang", "en");
        this.$store.commit('setLan','en')
        location.reload();
      }
    },
    show() {
      // 当用户的鼠标移入头像的时候，去显示操作菜单
      clearTimeout(this.hoverCtrl);
      this.hoverCtrl = setTimeout(() => {
        this.isHover = true;
      }, 200);
    },
    hide() {
      // 当用户的鼠标移出头像的时候，去隐藏操作菜单
      clearTimeout(this.hoverCtrl);
      this.hoverCtrl = setTimeout(() => {
        this.isHover = false;
      }, 500);
    },
    logout() {
      this.$confirm(
        "确定退出吗？",
        () => {
          localStorage.clear();
          this.$store.commit("setToken", "");
          this.$store.commit("setUserInfo", {});
          this.$store.commit("setIsLogin", false);
          this.$router.push({ name: "index" }, () => {});
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.input-full {
  flex-grow: 1;
}
.login-phone {
  border-radius: 6px 4px 4px 4px;
  border: 1px solid #4547DB;
  width:90%;
}
.form-control {
  border:none;
  color:#000;
  
}
.form-control:focus {
  color:#000;
}
[data-bs-theme="dark"] .logon .form-control{
   background: #fff;
   
}
[data-bs-theme="dark"] .btn-close1{
   background: #a4a1a1 !important;
   width: 2rem;
   height: 2rem;
   padding: 1px 3px;
   line-height: 2rem;
   text-align: center;
   border-radius: 50%;
   
}

.login-validate {
  border-radius: 6px 4px 4px 4px;
  border: 1px solid #4547DB;
  width:90%;
}
.center-fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    border-radius: 10px;
  }
  border-radius: 10px;
  background: #fff;
  padding: 0;
}
.search-box-icon {
  position: absolute;
  right: -1.5rem;
  -webkit-transform: translateY(20%);
  -ms-transform: translateY(20%);
  transform: translateY(20%);
}
.input-group {
  transform: translateX(14px);
  width: 70%;
}
.svg-inline--fa {
  height: 1.5rem;
}
.sanfang {
  
  img {
    width:30px;
    height: 30px;
  }
}
@media(max-width:500px) {
  .center-fixed {
    width:100%;
  }
}
</style>
