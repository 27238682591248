<template>
  <section class="pt-sm-0">
    <footer
      class="position-relative overflow-hidden pb-0 pt-6 pt-lg-6 d-none d-sm-block"
      data-bs-theme="dark"
    >
      <!-- SVG decoration -->
      <div class="position-absolute top-0 end-0 mt-n3 me-n4">
        <img
          src="../assets/images/elements/decoration-pattern-2.svg"
          style="opacity: 0.05"
          alt=""
        />
      </div>

      <div class="container position-relative mt-5">
        <div class="row g-6 justify-content-between">
          <div class="col-lg-4">
            <!-- logo -->
            <a class="me-0" href="index.html">
              <img
                width="500px"
                height="119px"
                class="light-mode-item"
                src="../assets/img/logo.png"
                alt="logo"
              />
              <img
                width="500px"
                height="119px"
                class="dark-mode-item"
                src="../assets/img/logob.png"
                alt="logo"
              />
            </a>

            <p class="mt-4 mb-2 budao-sub">Subscribe US</p>
            <div>
              <form class="bg-body rounded-2 mt-4">
                <div class="input-group">
                  <input
                    class="form-control focus-shadow-none border-0 budao-form"
                    type="email"
                    placeholder="info@yourgmail.com"
                  />
                  <button
                    type="button"
                    class="btn btn-dark rounded-2 budao-form-btn"
                  >
                    <img src="../assets/img/send.png" />
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="col-lg-8">
            <div class="row budao-btn-auto" style="margin-top: 50px">
              <!-- Link block -->
              <div
                class="budao-btn budao-btn-right text-center"
                @click="handlePost()"
              >
                <img src="../assets/img/post.png" alt="" class="me-2" />
                我要投稿
              </div>
              <div class="budao-btn text-center" @click="showBaodao()">
                <img
                  src="../assets/img/promoation.png"
                  alt=""
                  class="me-2"
                />寻求报道
              </div>
            </div>
            <div class="row mt-3">
              <ul class="list-inline mb-0 mt-3" :style="'padding-left:0px;'">
                <li
                  class="list-inline-item"
                  v-for="(item, index) in linklist"
                  :key="'linkitem' + index"
                >
                  <a class="btn btn-light btn-icon budao-btn-size" href="#"
                    ><i :class="item.css"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
          <!-- Widget 2 END -->
        </div>

        <!-- Bottom footer -->
        <div
          class="d-md-flex justify-content-between align-items-center text-center text-lg-start py-4"
        >
          <!-- copyright text -->
          <div class="text-body"></div>
          <!-- copyright links-->
          <!-- Language selector -->
        </div>
      </div>
      <!--报道窗口-->
      <div class="baodao-fixed" v-if="isBaodao">
        <button
          type="button"
          @click="isBaodao = false"
          class="btn-close position-absolute"
          style="top: 15px; right: 15px; color: #555555"
        ></button>
        <div class="row">
          <div class="col-4 d-none d-sm-block">
            <img
              style="
                height: 100%;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              "
              src="../assets/wapimg/shutiao.png"
              alt=""
            />
          </div>
          <div class="col-12 col-sm-8 p-3">
            <div class="d-flex align-items-center" style="margin-top: 10px">
              <div
                class="me-3"
                style="font-size: 22px; color: #555555; font-weight: 800"
              >
                稿件主题
              </div>
            </div>
            <div class="d-flex" style="margin-top: 10px">
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="稿件主题内容"
                />
              </div>
            </div>
            <div class="d-flex align-items-center" style="margin-top: 10px">
              <div
                class="me-3"
                style="font-size: 22px; color: #555555; font-weight: 800"
              >
                稿件链接
              </div>
            </div>
            <div class="d-flex" style="margin-top: 10px">
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="稿件链接内容"
                />
              </div>
            </div>
            <div class="d-flex align-items-center" style="margin-top: 10px">
              <div
                class="me-3"
                style="font-size: 22px; color: #555555; font-weight: 800"
              >
                作者联系方式
              </div>
            </div>
            <div class="d-flex" style="margin-top: 10px">
              <div class="w-100">
                <textarea
                  type="text"
                  class="form-control"
                  id=""
                  placeholder="作者联系方式"
                  rows="3"
                >
                </textarea>
              </div>
            </div>

            <div style="margin-top: 40px" class="">
              <button class="btn btn-primary" style="width: 100%">提交</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="d-sm-none container bottom-nav">
      <ul class="list-inline mx-auto d-flex justify-content-around">
        <li class="list-inline-item text-center" @click="handleNavClick(0)">
          <a class="" href="/">
            <div
              class="wap-icon text-center"
              :class="navIndex == 0 ? 'iselect' : ''"
            >
              <img
                style="width: 32px; height: 32px"
                src="../assets/wapimg/home.png"
                alt=""
              />
            </div>

            <div>主页</div>
          </a>
        </li>
        <li class="list-inline-item text-center" @click="handleNavClick(1)">
          <a href="/#/Article">
            <div
              class="wap-icon text-center"
              :class="navIndex == 1 ? 'iselect' : ''"
            >
              <img src="../assets/wapimg/article.png" alt="" />
            </div>

            <div>快讯</div>
          </a>
        </li>
        <li class="list-inline-item text-center">
          <a href="http://ai.budaonews.com/">
            <div class="wap-icon text-center">
              <img src="../assets/wapimg/yj.png" alt="" />
            </div>

            <div>AI</div>
          </a>
        </li>
        <li class="list-inline-item text-center" @click="handleNavClick(2)">
          <a href="/#/bdtypage">
            <div
              class="wap-icon text-center"
              :class="navIndex == 2 ? 'iselect' : ''"
            >
              <img src="../assets/wapimg/hot.png" alt="" />
            </div>

            <div>热门</div>
          </a>
        </li>
        <li class="list-inline-item text-center" @click="handleNavClick(3)">
          <a href="#/user">
            <div
              class="wap-icon text-center"
              :class="navIndex == 3 ? 'iselect' : ''"
            >
              <img src="../assets/wapimg/my.png" alt="" />
            </div>

            <div>我的</div>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/vendor/ityped/index.js";
import "swiper/bundle";
import { getAllLink } from "@/api/footlink";
//import '../assets/js/cale.js'
//import '../assets/js/functions.js'

export default {
  name: "budao_footer",
  data() {
    return {
      navIndex: 0,
      linklist: [],
      isShow: false,
      isBaodao: false,
    };
  },
  created() {
    this.init();
    this.getLink();
  },
  methods: {
    handleNavClick(index) {
      this.navIndex = index;
    },
    showBaodao() {
      this.isBaodao = true;
    },
    init() {
      const storedTheme = localStorage.getItem("theme");

      const getPreferredTheme = () => {
        if (storedTheme) {
          return storedTheme;
        }
        return window.matchMedia("(prefers-color-scheme:dark)").matches
          ? "light"
          : "dark";
      };

      const setTheme = function (theme) {
        if (
          theme === "auto" &&
          window.matchMedia("(prefers-color-scheme:dark)").matches
        ) {
          document.documentElement.setAttribute("data-bs-theme", "dark");
        } else {
          document.documentElement.setAttribute("data-bs-theme", theme);
        }
      };
      console.log(
        "current:",
        window.matchMedia("(prefers-color-scheme: light)").matches
      );
      setTheme(getPreferredTheme());

      window.addEventListener("DOMContentLoaded", () => {
        const el = document.querySelector(".theme-icon-active");
        if (el != "undefined" && el != null) {
          const showActiveTheme = (theme) => {
            const activeThemeIcon = document.querySelector(
              ".theme-icon-active use"
            );
            const btnToActive = document.querySelector(
              `[data-bs-theme-value="${theme}"]`
            );
            const svgOfActiveBtn = btnToActive
              .querySelector(".mode-switch use")
              .getAttribute("href");

            document
              .querySelectorAll("[data-bs-theme-value]")
              .forEach((element) => {
                element.classList.remove("active");
              });

            btnToActive.classList.add("active");
            activeThemeIcon.setAttribute("href", svgOfActiveBtn);
          };

          // window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
          // 	console.log("match:dark")
          // 	if (storedTheme !== 'light' || storedTheme !== 'dark') {
          // 		setTheme(getPreferredTheme())
          // 	}

          // })
          // window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', () => {
          // 	console.log("match: light")
          // })

          showActiveTheme(getPreferredTheme());

          document
            .querySelectorAll("[data-bs-theme-value]")
            .forEach((toggle) => {
              toggle.addEventListener("click", () => {
                const theme = toggle.getAttribute("data-bs-theme-value");
                localStorage.setItem("theme", theme);
                setTheme(theme);
                showActiveTheme(theme);
              });
            });
        }
      });
    },
    show() {
      this.isShow = true;
    },
    hide() {
      setTimeout(() => {
        this.isShow = false;
      }, 500);
    },

    handlePost() {
      this.$pop("shark", "未登陆，不能投稿");
    },
    getLink() {
      getAllLink().then((res) => {
        if (res.state === 200) {
          this.linklist = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-nav {
  padding-top: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.baodao-fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img {
    border-radius: 10px;
  }
  border-radius: 10px;
  background: var(--bs-secondary-bg);
  padding: 0;
  z-index: 999;
}
.budao-btn-size {
  width: 89px;
  height: 89px;
  font-size: 34px;
}
.budao-form {
  height: 88px;
  font-size: 28px;
}
.budao-form-btn {
  width: 96px;
  background: linear-gradient(0deg, #4547db 0%, #595ef7 98%);
}
.budao-form-btn svg {
  font-size: 36px;
}
@media (min-width: 750px) {
  .budao-btn-size {
    margin-right: 39px;
  }
}
@media (max-width: 750px) {
  .budao-btn-auto {
    margin: auto;
  }
}

.budao-btn-right {
  margin-right: 69px;
}
.budao-btn {
  width: 342px;
  height: 112px;

  border-radius: 12px;
  border: 1px solid #4547db;

  font-family: PingFang SC;
  font-weight: bold;
  font-size: 34px;
  color: #ffffff;
  text-align: center;
  line-height: 112px;
}
[data-bs-theme="light"] .budao-btn {
  color: #000;
}
.budao-sub {
  font-family: PingFang SC;
  font-size: 36px;
  font-weight: 800;
  color: #ffffff;
}
.wap-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.iselect {
  background: #4547db;
  border-radius: 10px;
}
.form-control {
  background-color: #2b2a29;
}
[data-bs-theme="light"] .form-control {
  background-color: #2b2a29;
}
</style>
