// import store from '@/store'
//import moment from 'dayjs'
import routes from "./routers";

import Vue from "vue";
//import Router from 'vue-router'
import Router from "vue-router";

Vue.use(Router);

const router = new Router(routes);
// router.beforeEach((to,from,next)=>{
//     if(to.path == from.path) {
//         next(false)
//     } else {
//         next()
//     }
// })
// 1. 基于角色的路由守卫
// 2. 组件级的权限控制 -> directive
// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('token')
//   const userInfo = JSON.parse(localStorage.getItem('userInfo'))
//   if (token !== '' && token !== null) {
//     const payload = JSON.parse(atob(token.split('.')[1]))
//     if (moment().isBefore(moment(payload.exp * 1000))) {
//       // 取localStorage里面缓存的token信息 + 用户信息
//       // 8-24小时， refresh token 1个月
//       store.commit('setToken', token)
//       store.commit('setUserInfo', userInfo)
//       store.commit('setIsLogin', true)
//       if (!store.state.ws) {
//         store.commit('initWebSocket', {})
//       }
//     } else {
//       localStorage.clear()
//     }
//   }
//   // to and from are Route Object,next() must be called to resolve the hook
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     const isLogin = store.state.isLogin
//     // 需要用户登录的页面进行区别
//     if (isLogin) {
//       // 已经登录的状态
//       // 权限判断，meta元数据
//       next()
//     } else {
//       // 未登录的状态
//       next('/login')
//     }
//   } else {
//     // 公共页面，不需要用户登录
//     next()
//   }
// })
router.afterEach(() => {
    window.scrollTo(0, 0);
})
export default router;
