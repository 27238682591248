const isDev = process.env.NODE_ENV === "development";

export default {
  baseUrl: {
    dev: "http://api.budaonews.com",
    pro: "http://api.budaonews.com",
  },
  publicPath: [/^\/public/, /^\/login/],
  wsconfig: {
    url: isDev ? "127.0.0.1" : "api.budaonews.com",
    port: isDev ? "3001" : 22001,
  },
};
